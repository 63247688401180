/* some default styles go in textstyles.css so the editor looks more like the page.*/
/* ******************************************************************** */
/* jquery validate 														 */
/* ******************************************************************** */
.error {
	color:red;
}
.clearfix:after {
 content: ".";
 display: block;
height: 0;
 clear: both;
 visibility: hidden;
 }

/* ******************************************************************** */
/* Common elements 														*/
/* ******************************************************************** */

html, body { /*reset */
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size:16px;
}

header, footer, section, article, nav {
    display: block;
}

.contentwrap {
    position: relative;
    max-width: 1200px;
    padding: 40px 20px;
    margin: 0 auto;
}
a,  /* IE fix */
img {
    border:0;
    outline:0;
}
img,
iframe {
    max-width: 100%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
img {
    height: auto !important;
}
a {
    display: inline-block;
    text-decoration: none;
    color: inherit;
}
#content a,
#content a h2 {
    color: #ed0d6e !important;
    border-bottom: 1px solid #bfd62e;
}
.pink #content a,
.pink #content a h2 {
    color: #bfd62e !important;
    border-bottom: 1px solid #691F7D;
}

h1.logo,
a.logo {
    display: inline-block;
    vertical-align: top;
    background-color: #FFF;
    width: 180px;
    height: auto;
    font-family: 'Montserrat', Arial, sans-serif;
    text-transform: uppercase;
    color: #67217a;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;
    padding: 20px 30px;
    box-sizing: border-box;
    margin: 0;
}
.logo img {
    width: 140px;
    height: 125px;
    margin-bottom: 10px;
}

/** social links **/
header .site-links,
footer .site-links {
    display: inline-block;
    vertical-align: middle;
}
.site-link {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 5px 2px;
}

/*** nav styles ***/
nav ul {
    list-style: none;
    margin:0;
    padding:0;
}
nav ul:after {
    content:"";
    display: block;
    /* clear li floats */
    clear: both;
}
nav li {
    display:inline-block;
    vertical-align: middle;
    text-transform: uppercase;
}
nav li > a {
    display: block;
    width: 100%;
    box-sizing: border-box;
}
nav li li {
    display:block;
    line-height:1;
    font-size:14px;
}
nav li li a {
    line-height:1;
    min-height:0;
    padding:5px 20px;
}
nav li .subtitle {
    display: none;
}

/** layout styles **/
.table-wrapper {
    margin-left: -40px;
    margin-right: -40px;
}
.table-wrapper .table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 40px;
}
.table-wrapper .col {
    vertical-align: middle;
}
.table {
    display: table;
    table-layout: fixed;
    width:100%;
}
.row {
    display: table-row;
    width: 100%;
}
.col {
    display: table-cell;
    vertical-align: top;
    width: 100%;
}
.half {
    width: 48%;
    display: inline-block;
    vertical-align: top;
}
.table-responsive-wrap {
    width:100%;
    overflow-x:scroll;
}

/* global form styles */
form p,
.overview form p {
    position: relative;
    text-align: left;
    margin: 10px 0;
}
form .half {
    position: relative;
    display: inline-block;
    width: 50%;
    padding-top: 15px;
    box-sizing: border-box;
}
form .half:nth-of-type(odd) {
    padding-right: 1%;
}
form .half:nth-of-type(even){
    padding-left: 1%;
}
form input,
form button,
form textarea,
form select,
form label {
    display: block;
    width: 100%;
    padding: 0 10px;
    font-family: 'Myriad', Arial, sans-serif;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    padding-left: 20px;
    box-sizing: border-box;
    border:0;
    box-sizing: border-box;
}
form input,
form textarea,
form select {
    background-color: #e5eaf0;
}
form textarea {
    min-height: 60px;
    padding: 10px;
}
.infield-active label.infield {
    position: absolute;
    background-color: transparent;
    color:grey;
    z-index: 2;
}
form select {
    color: grey;
}
label.error {
    position: absolute;
    top:0;
    background-color: transparent;
    font-size: 12px;
    height: auto;
    line-height: 1;
}
form button {
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    color: #FFF;
    line-height:1;
    background-color: #691F7D;
}
/* ==========================================================================
   Header
   ========================================================================== */
header .contentwrap {
    padding: 0 20px;
}
header .wrapper {
    position: relative;
    padding-left: 180px;
    box-sizing: border-box;
}
header .logo {
    position: absolute;
    left: 0;
    top: 0;
    text-align: left;
    z-index: 10;
}

/** top bar **/
header .top-bar {
    text-align: right;
}
header .top-bar > div,
header .top-bar nav {
    display: inline-block;
    vertical-align: top;
}
.top-bar nav li {
    margin: 10px 2px;
}
.top-bar nav a {
    text-align: center;
    line-height: 30px;
    color: #ed0d6e;
    font-size: 13px;
    padding: 0 6px;
    transition: color 0.3s ease-in-out;
}
.top-bar nav a:hover {
    color: #bfd62e;
}
.top-bar nav a > span {
    display: inline-block;
    border-bottom: 1px solid #bfd62e;
}
.top-bar nav .subtitle {
    display: none;
}
.top-bar nav li > ul { /* one-level only */
    display: none;
}
header .site-links {
    margin: 10px 0;
}

form.search {
    display: inline-block;
    vertical-align: top;
    height: 30px;
    line-height: 30px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 10px;
    margin-right: 20px;
}
form.search .wrap {
    position: relative;
    display: block;
    height: 30px;
    width: auto;
    outline: 2px solid #8c639f;
}

form.search .button-search {
    display: inline-block;
    vertical-align: top;
    width: 32px;
    height: 30px;
    font-size:0;
    background: #8c639f url("../assets/img/search.svg") no-repeat;
    background-size: 20px 20px;
    background-position: center;
    cursor: pointer;
}
form.search input {
    display: inline-block;
    vertical-align: top;
    width: 150px;
    height: 30px;
    line-height: 30px;
    background-color: #FFF;
    padding: 0;
    padding-left: 10px;
}
form.search label.infield {
    position: absolute;
    top:0;
    left:0;
    max-width: 100%;
    width: auto;
    height: 30px;
    padding-left: 10px;
    line-height: 30px;
    z-index: 2;
}

/********** bottom-bar **********/
.bottom-bar {
    position: relative;
    min-height: 60px;
}
/** header menu **/
.bottom-bar nav {
    position: relative;
    text-align: right;
    color: #676767;
}
.bottom-bar nav > ul {
    display: inline-block;
    vertical-align: top;
}
.bottom-bar nav li {
    text-align: center;
}
.bottom-bar nav li a:hover,
.bottom-bar nav li.active > a {
    background: #691F7D;
    color:#ffffff !important;
}
.bottom-bar nav a {
    display: block;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}

.bottom-bar nav li a:hover .subtitle,
.bottom-bar nav li a:hover .menu-suffix,
.bottom-bar nav li.active .subtitle,
.bottom-bar nav li.active .menu-suffix,
.bottom-bar nav li li.depth-1.active a.menu-suffix {
    color:#ffffff;
}

/** menu position hover bg colours **/
.bottom-bar nav > ul > li:nth-of-type(7n+1) a:hover,
.bottom-bar nav > ul > li:nth-of-type(7n+1).active > a,
.bottom-bar nav > ul > li:nth-of-type(7n+1) li.active > a,
.slide-menu > ul > li:nth-of-type(7n+1) a:hover,
.slide-menu > ul > li:nth-of-type(7n+1).active > a,
.slide-menu > ul > li:nth-of-type(7n+1) li.active > a {
     background: #005950; /* jade */
}
.bottom-bar nav > ul > li:nth-of-type(8n+2) a:hover,
.bottom-bar nav > ul > li:nth-of-type(8n+2).active > a,
.bottom-bar nav > ul > li:nth-of-type(8n+2) li.active > a,
.slide-menu > ul > li:nth-of-type(8n+2) a:hover,
.slide-menu > ul > li:nth-of-type(8n+2).active > a,
.slide-menu > ul > li:nth-of-type(8n+2) li.active > a{
    background: #013974; /* navy */
}
.bottom-bar nav > ul > li:nth-of-type(9n+3) a:hover,
.bottom-bar nav > ul > li:nth-of-type(9n+3).active > a,
.bottom-bar nav > ul > li:nth-of-type(9n+3) li.active > a,
.slide-menu > ul > li:nth-of-type(9n+3) a:hover,
.slide-menu > ul > li:nth-of-type(9n+3).active > a,
.slide-menu > ul > li:nth-of-type(9n+3) li.active > a{
    background: #691F7D; /* purple */
}
.bottom-bar nav > ul > li:nth-of-type(10n+4) a:hover,
.bottom-bar nav > ul > li:nth-of-type(10n+4).active > a,
.bottom-bar nav > ul > li:nth-of-type(10n+4) li.active > a,
.slide-menu > ul > li:nth-of-type(10n+4) a:hover,
.slide-menu > ul > li:nth-of-type(10n+4).active > a,
.slide-menu > ul > li:nth-of-type(10n+4) li.active > a{
    background: #ed0d6e; /* pink */
}
.bottom-bar nav > ul > li:nth-of-type(11n+5) a:hover,
.bottom-bar nav > ul > li:nth-of-type(11n+5).active > a,
.bottom-bar nav > ul > li:nth-of-type(11n+5) li.active > a,
.slide-menu > ul > li:nth-of-type(11n+5) a:hover,
.slide-menu > ul > li:nth-of-type(11n+5).active > a,
.slide-menu > ul > li:nth-of-type(11n+5) li.active > a{
    background: #f1b519; /* mustard */
}
.bottom-bar nav > ul > li:nth-of-type(12n+6) a:hover,
.bottom-bar nav > ul > li:nth-of-type(12n+6).active > a,
.bottom-bar nav > ul > li:nth-of-type(12n+6) li.active > a,
.slide-menu > ul > li:nth-of-type(12n+6) a:hover,
.slide-menu > ul > li:nth-of-type(12n+6).active > a,
.slide-menu > ul > li:nth-of-type(12n+6) li.active > a {
    background: #bfd62e; /* lime */
}

.bottom-bar nav li li {
    text-align:left;
}

/** level 1 **/
header li.depth-0 {
    font-weight: bold;
}
.bottom-bar nav li.depth-0 > a {
    position:relative;
    width: 100%;
    min-width: 120px;
    line-height: 60px;
    padding:0 20px;
    text-decoration: none;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

}
/** level 2 **/
.bottom-bar nav li.depth-0 > ul {
    display:none;
    position:absolute;
    top:100%;
    left:0;
    min-width:100%;
    text-align:left;
    background: #e5eaf0;
    z-index:1000;
}
.bottom-bar nav li.depth-0 > a .menu-suffix-text {
    display:none;
}
.bottom-bar nav li.depth-0:hover > ul {
    display:block;
    padding-top: 20px;
    padding-left:300px; /* we put an image here */
    padding-bottom:20px;
    box-sizing:border-box;
}
.bottom-bar nav li.depth-1 {
    width:31%;
    /*float:left;*/
    display:inline-block;
    vertical-align:top;
    padding-bottom:20px;
}
/** level 3 **/
.bottom-bar nav li li .subtitle,
.bottom-bar nav li li .menu-suffix,
.bottom-bar nav li li.depth-1 a.menu-suffix,
.menu-suffix-text {
   display:block;
   color:inherit;
   font-weight:normal;
   text-transform:none;
   padding:5px 0;
}
.bottom-bar nav li li .menu-suffix,
.bottom-bar nav li li.depth-1 a.menu-suffix  {
   padding:5px 20px;
}
.bottom-bar nav li li.depth-1 > a {
    font-weight:bold;
}
.bottom-bar nav li li > ul {
    top:0;
    left:100%;
    color:#691F7D;
    display:inline;
}
.bottom-bar nav li li li {
    display:inline-block;
}

.bottom-bar nav .menu-graphic {
    position: absolute;
    left: 0;
    width: 300px;
    top: 0;
    height: 100%;
    max-height:400px;
    background-size: cover;
    background-position:center;
    display:none;
}
.bottom-bar nav li li:first-child .menu-graphic {
    display:block;
}

#mobile-menu {
    display: none;
    position: absolute;
    bottom: 10px;
    right: 0;
    background: #bfd62e;
    background-size: 24px;
    font-size: 24px;
    padding: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index:100;
}
#mobile-menu > span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #FFF;
    border-radius: 3px;
    margin: 5px 0;
    opacity:1;
    transition: opacity 0.3s ease-in-out;
}
#mobile-menu span.one {
    margin-top: 4px;
}
.slide-menu-active #mobile-menu span.one {
    -webkit-transform: rotate(-45deg) translate(-5px, 5px);
    transform: rotate(-45deg) translate(-5px, 5px);
}
.slide-menu-active #mobile-menu span.two {
     opacity: 0;
}
.slide-menu-active #mobile-menu span.three {
    -webkit-transform: rotate(45deg) translate(-6px, -6px) ;
    transform: rotate(45deg) translate(-6px, -6px) ;
}
/***** Slide menu ****/
.slide-menu {
    display:none;
    text-align:left;
	background: #ccc;
    width:240px;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	right: -241px;
	border-left: 1px solid #000;
	overflow-y:scroll;
}
.slide-menu-active .slide-menu {
    right: 0px;
}
.site-content-menu-slide {
    overflow-x:hidden;
    position:relative;
    right:0;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
    -o-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

/** top section **/
.slide-menu  .extras {
    display: none;
}
.slide-menu .extras nav,
.slide-menu .extras form {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
}
.slide-menu .extras nav a {
    display: block;
    color: #FFF;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
    line-height:1;
    color: #ed0d6e;
    margin:0;
}
.slide-menu .extras nav a:hover {
    color: #FFF;
    background-color: #ed0d6e;
}
/** form **/
.slide-menu form.search,
.slide-menu form.search label.infield,
.slide-menu form.search .wrap,
.slide-menu form.search .button-search,
.slide-menu form.search input {
    height: 50px;
    line-height: 50px;
}
.slide-menu form.search {
    padding: 0;
    margin:0;
}
.slide-menu form.search .wrap {
    padding-right: 50px;
    outline: 0;
    border: 2px solid #8c639f;
    border-left: 0;
    box-sizing: border-box;
}
.slide-menu form.search .button-search {
    position: absolute;
    top:0;
    right: 0;
    width: 50px;
}
.slide-menu form.search input {
    width: 100%;
}
.slide-menu form.search label.infield,
.slide-menu form.search input {
    padding-left: 18px;
}

/** regular **/
.slide-menu h3 {
	padding: 1em;
	color:#fff;
	background: #bfd62e;
	border-bottom: 1px solid #666;
	margin:0;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu ul ul {
	background:#999;
}
.slide-menu ul ul ul{
	background:#666;
}
nav.slide-menu li {
    position:relative;
    display:block;
}
.slide-menu li > ul {
    display:none;
}
.slide-menu li.expand-active > ul {
    display:block;
}
.slide-menu a {
	width: auto;
	color: #010101;
	font-size: 1.1em;
	font-weight: 300;
	border-bottom: 1px solid #666;
	padding: 18px;
	padding-right: 10px;
	text-decoration:none;
	transition: all 0.3s ease-in-out;
}
.slide-menu a:hover {
/* default is lime - positions set above */
    color: #FFF;
	background: #BED62F;
}
.slide-menu li.has-sub > a {
    position: relative;
    border-right: 1px solid #000;
    margin-right: 50px;
}
.slide-menu li.has-sub > a::after {
    content:"";
    position: absolute;
    bottom: -1px;
    left: 100%;
    width: 50px;
    height: 1px;
    background-color: #000;
    z-index: 1;
}
.slide-menu ul .expand {
    position: absolute;
    top: 0;
    right: 0;
    height: 59px;
    width: 50px;
    cursor: pointer;
}
.slide-menu ul .expand i {
    content:"";
    display: block;
    height: 62px;
    width: 48px;
    background: url('../assets/img/menu-drop.svg') no-repeat center center;
    background-size: 30%;
    -webkit-transition: -webkit-transform 500ms ease;
    -moz-transition: -moz-transform 500ms ease;
    -o-transition: -o-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.expand-active > .expand i {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
/** hide course extras **/
.slide-menu a.menu-suffix {
    display: none;
}

/* ==========================================================================
   Footer
   ========================================================================== */
footer {
    text-align: left;
}
footer .top .contentwrap:after {
    content:"";
    display: table;
    width: 100%;
    height: 1;
    float: none;
}
footer .logo {
    padding-top:0;
    margin-right: -20px;
    text-align: right;
    float: right;
}
footer h2 {
    font-size: 32px;
    line-height: 1;
    text-transform: uppercase;
    margin-top: 0;
}
.footer-contact p,
.footer-contact .social {
    color: #010101;
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0;
}
.footer-contact span.purple {
    display: inline-block;
    min-width: 85px;
    text-transform: uppercase;
    font-weight: bold;
}
.social .site-links {
    display: inline-block;
}
.footer-contact .site-link {
    width: 25px;
    height: 25px;
}

/** bottom **/
footer .bottom {
    background-color: #e5eaf0;
    color: #83868b;
}
footer .bottom .contentwrap {
    padding-bottom: 0;
}
footer .bottom .table > div {
    display: table-cell;
    vertical-align: top;
}
.abn {
    padding-right: 10px;
}

/** editable text **/
.smartSkilled {
    display: inline-block;
    color: #83868b !important;
    text-align: right !important;
}
.smartSkilled img {
    max-width: 65px;
    max-height: 70px;
}
.smartSkilled img,
.smartSkilled h4 {
    display: inline-block;
    vertical-align: bottom;
    margin:0;
}
.smartSkilled p {
    margin: 5px 0;
}
.smartSkilled a {
    text-decoration: none;
}

/*** by keo ***/
.bykeo {
    vertical-align: middle;
    text-align: center;
    font-size: 12px;
    padding: 1% 0;
    clear:both;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translateX(-200%) rotate(-360deg);
    -ms-transform: translateX(-200%) rotate(-360deg);
    -o-transform: translateX(-200%) rotate(-360deg);
    -moz-transform: translateX(-200%) rotate(-360deg);
    transform: translateX(-200%) rotate(-360deg);
}

/* ==========================================================================
   Index html
   ========================================================================== */
/******** banner styles ********/
.banners-home {
    position: relative;
    width: 100%;
    height: 62%;
    height: 62vh;
}
.banner-stage {
    width: 100%;
    height: 100%;
    position:relative;
    background-color: #d3d3d3;
}
.banner-stage .banner:before { /* lighten */
    content:"";
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(255,255,255, 0.2);
}

.top-banner .banner::after,
.banner-stage::after {
    content:"";
    position: absolute;
    top:0;
    left:0;
    width: 1.5%;
    height: 100%;
    background: -moz-linear-gradient(bottom, rgba(191,214,46,1) 0%, rgba(191,214,46,1) 16%,
                                          rgba(241,181,25,1) 16%, rgba(241,181,25,1) 33%,
                                          rgba(237,13,110,1) 33%, rgba(237,13,110,1) 50%,
                                          rgba(105,31,125,1) 50%, rgba(105,31,125,1) 66%,
                                          rgba(1,57,116,1) 66%, rgba(1,57,116,1) 83%,
                                          rgba(0,89,80,1) 83%, rgba(0,89,80,1) 100%);
    background: -webkit-gradient(left bottom, left top,
                                 color-stop(0%, rgba(191,214,46,1)), color-stop(16%, rgba(191,214,46,1)),
                                 color-stop(16%, rgba(241,181,25,1)), color-stop(33%, rgba(241,181,25,1)),
                                 color-stop(33%, rgba(237,13,110,1)), color-stop(50%, rgba(237,13,110,1)),
                                 color-stop(50%, rgba(105,31,125,1)), color-stop(66%, rgba(105,31,125,1)),
                                 color-stop(66%, rgba(1,57,116,1)), color-stop(83%, rgba(1,57,116,1)),
                                 color-stop(83%, rgba(0,89,80,1)), color-stop(100%, rgba(0,89,80,1)));
    background: -webkit-linear-gradient(bottom,
                                rgba(191,214,46,1) 0%, rgba(191,214,46,1) 16%,
                                rgba(241,181,25,1) 16%, rgba(241,181,25,1) 33%,
                                rgba(237,13,110,1) 33%, rgba(237,13,110,1) 50%,
                                rgba(105,31,125,1) 50%, rgba(105,31,125,1) 66%,
                                rgba(1,57,116,1) 66%, rgba(1,57,116,1) 83%,
                                rgba(0,89,80,1) 83%, rgba(0,89,80,1) 100%);
    background: -o-linear-gradient(bottom,
                                rgba(191,214,46,1) 0%, rgba(191,214,46,1) 16%,
                                rgba(241,181,25,1) 16%, rgba(241,181,25,1) 33%,
                                rgba(237,13,110,1) 33%, rgba(237,13,110,1) 50%,
                                rgba(105,31,125,1) 50%, rgba(105,31,125,1) 66%,
                                rgba(1,57,116,1) 66%, rgba(1,57,116,1) 83%,
                                rgba(0,89,80,1) 83%, rgba(0,89,80,1) 100%);
    background: -ms-linear-gradient(bottom,
                                rgba(191,214,46,1) 0%, rgba(191,214,46,1) 16%,
                                rgba(241,181,25,1) 16%, rgba(241,181,25,1) 33%,
                                rgba(237,13,110,1) 33%, rgba(237,13,110,1) 50%,
                                rgba(105,31,125,1) 50%, rgba(105,31,125,1) 66%,
                                rgba(1,57,116,1) 66%, rgba(1,57,116,1) 83%,
                                rgba(0,89,80,1) 83%, rgba(0,89,80,1) 100%);
    background: linear-gradient(to top,
                                rgba(191,214,46,1) 0%, rgba(191,214,46,1) 16%,
                                rgba(241,181,25,1) 16%, rgba(241,181,25,1) 33%,
                                rgba(237,13,110,1) 33%, rgba(237,13,110,1) 50%,
                                rgba(105,31,125,1) 50%, rgba(105,31,125,1) 66%,
                                rgba(1,57,116,1) 66%, rgba(1,57,116,1) 83%,
                                rgba(0,89,80,1) 83%, rgba(0,89,80,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfd62e', endColorstr='#005950', GradientType=0 );
    z-index: 3;
}

.banner-stage > .banner {
    position:absolute;
    display: block;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width: 100%;
    height: 100%;
    background-position: 70%;
    background-size: cover;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    opacity:0;
    -webkit-transition: opacity 1s ease;
    -moz-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    transition: opacity 1s ease;
    z-index:0;
}
.banner-stage > .banner.active {
    opacity:1;
    z-index:1;
}
.banner-navigation,
.banner-content {
    display: block;
    max-width: 1200px;
    margin: 0 auto;
}
.banner-content {
    text-align: left;
    font-size: 50px;
    line-height:1;
    text-transform: uppercase;
    padding: 200px 20px;
    padding-right: 50%;
    box-sizing: border-box;
}
.banner-content > span {
    display: block;
}
.banner-content > .banner-name {
    display: none;
}
.banner-caption {
    font-size: 18px;
    color: #FFF;
    margin-top: 10px;
}
.banner-caption > span {
    display: inline-block;
    font-weight: bold;
    padding: 15px 30px;
    max-width: 100%;
    background-color: #ed0d6e;
    border: 2px solid #ed0d6e;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.banner-caption > span:hover {
    color: #ed0d6e;
    background-color: #FFF;
}
.banner-navigation {
    display: none;
    position: absolute;
    left:0;
    bottom:0;
}

/** home content **/
.home h2 {
    font-size: 30px;
    text-transform: uppercase;
    margin: 0;
}
.home .page-content.has-content {
    padding-bottom: 40px;
}

/****** home middle ******/
#course-search,
.be-inspired li {
    text-align: left;
    box-sizing: border-box;
}
.be-inspired .button {
    border: 0 !important;
}
.courses-scroller,
.links {
    text-align: center;
}

/** course search form **/
#course-search form {
    background: #013974;
    color: #FFF;
    padding: 30px;
    box-sizing: border-box;
}
#course-search form h2 {
    color: #FFF;
}
#course-search form:after {
    content:"";
    display: table;
    width: 100%;
    height: 1px;
    float: none;
}
#course-search label {
    color: grey;
    padding-left: 20px;
    line-height: 50px;
    text-transform: uppercase;
}
#course-search form input,
#course-search form select {
    background-color: #FFF;
    color: #010101;
    height: 50px;
    line-height: 50px;
}
#course-search form input {
    padding-right: 50px;
    background-image: url('../assets/img/course-search.svg');
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: 98%;
}
#course-search select {
    text-transform: uppercase;
}
#course-search option {
    display: block;
    color: #010101;
    height: 30px;
}
#course-search option:first-child {
    color: grey;
}
#course-search form button {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    background: #ed0d6e;
    font-size: 20px;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    margin-top: 20px;
    float: right;
}


/** course scroller  **/
.scroll-wrapper {
    position: relative;
    padding: 0px 40px;
    margin-left: -40px;
    margin-right: -40px;
}
.scroll-wrapper .prev,
.scroll-wrapper .next {
    position: absolute;
    top: 50%;
    margin-top: -100px;
    width: 40px;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    z-index:100;
    cursor: pointer;
}
.scroll-wrapper .prev {
    left:0;
    background-image: url('../assets/img/left.svg');
}
.scroll-wrapper .next {
    right:0;
    background-image: url('../assets/img/right.svg');
}
.courses-scroller {
    padding-top: 40px;
}
.category-scroller {
    position: relative;
    /* height: 400px; */
    text-align: center;
    overflow: hidden;
    margin-top: 40px;
}
.category-slider {
    white-space: nowrap;
    max-width:100%;
}
.category-scroller .category {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 33.33%;
    background-image: url('../assets/img/category-fallback.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #FFF;
    box-sizing: border-box;
}
.category-scroller .category::before {
    content:"";
    display: block;
    width: 100%;
    padding-top: 100%; /* square */
    transition: background-color 0.3s ease-in-out;
}
.category-scroller .category .name {
    position: absolute;
    bottom:0;
    left:0;
    display: block;
    width: 100%;
    min-height: 55px;
    background: #000;
    color: #FFF;
    font-weight: bold;
    padding: 5% 10%;
    white-space: normal;
    box-sizing: border-box;
}

/* category colours */
.category-scroller .purple.category:hover::before {
    background-color: rgba(105,31,125,0.3);
}
.category-scroller .purple.category .name {
    background-color: #691F7D;
}

.category-scroller .pink.category:hover::before {
    background-color: rgba(237,13,110,0.3);
}
.category-scroller .pink.category .name {
    background-color: #ed0d6e;
}

.category-scroller .mustard.category:hover::before {
    background-color: rgba(241,181,25,0.3);
}
.category-scroller .mustard.category .name {
    background-color: #f1b519;
}

.category-scroller .lime.category:hover::before {
    background-color: rgba(191,214,46,0.3);
}
.category-scroller .lime.category .name {
    background-color: #bfd62e;
}

.category-scroller .jade.category:hover::before {
    background-color: rgba(0,89,80,0.3);
}
.category-scroller .jade.category .name {
    background-color: #005950;
}

.category-scroller .navy.category:hover::before {
    background-color: rgba(1,57,116,0.3);
}
.category-scroller .navy.category .name {
    background-color: #013974;
}

.category-scroller .category .name .col {
    position: relative;
    vertical-align: top;
    text-align: left;
    z-index: 2;
    width:100%;
}

/*** services-nav + be-inspired navs ***/
.services {
    text-align: center;
    padding: 40px 0;
    box-sizing: border-box;
}
.services nav ul,
.be-inspired nav ul {
    display: table;
    table-layout: fixed;
    width: 100%;
}
.services nav li,
.be-inspired nav li {
    display: table-cell;
    vertical-align: top;
    width: 100%;
    text-transform: none;
    color: #010101;
}
.services nav li {
    text-align: center;
    transition: background-color 0.3s ease-in-out;
}
.services li > a {
    padding: 20px;
}
.services li:hover {
    background-color: #f3f4f8;
}
.services li span,
.be-inspired li span {
    display: block;
}
.services .title,
.be-inspired .title {
    font-weight: bold;
    margin: 20px 0;
}
.services .menu-icon {
    display: block;
    vertical-align: top;
    max-height: 100px;
    margin:0 auto;
    position: relative;
}

/*** student-exp ***/
.student-exp,
.links {
    padding-top: 60px;
}
.student-exp img {
    width: 100%;
}
/*** links ***/
.links {
    vertical-align: top;
    overflow: hidden;
}
.links .link {
    position: relative;
    display: table-cell;
    vertical-align: top;
    width: 50%;
    height: 100%;
    background-color: #BED62F;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.links .link:first-of-type {
    border-right: 10px solid #FFF;
}
.links .link:last-of-type {
    border-left: 10px solid #FFF;
}
.links .img {
    display: table;
    width: 100%;
    padding-top: 150%;
    height: 70%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 40% center ;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.links .label {
    display: table;
    width: 100%;
    height: 30%;
    background: #BED62F;
    padding: 2% 10%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.links .label .col {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: top;
}
.links .label h4 {
    color: #000;
    margin: 0;
    margin-bottom: 5px;
}

/********** be inspired section **********/
.be-inspired {
    background: #f3f4f8;
    text-align: left;
}
.be-inspired .contentwrap {
    padding: 0;
}
.be-inspired li {
    transition: background-color 0.2s ease-in-out;
}
.be-inspired li:hover {
    background-color: #dadee9;
}
.be-inspired li > a {
    padding: 40px 20px;
}
.be-inspired .menu-icon {
    width: 100%;
    display:block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
}
.be-inspired li .title {
    text-align: left;
    margin-top: 40px;
}
.be-inspired li .subtitle {
    text-align: left;
    margin: 0;
}
.be-inspired li span.button {
    display: inline-block;
    color: #FFF;
    font-size: 14px;
    background-color: #a2a3a7;
    margin: 40px auto;
    margin-bottom: 0;
    transition: background-color 0.2s ease-in-out;
}
.be-inspired li:hover span.button {
    background-color: #242c41;
}

/* ==========================================================================
   General Other templates
   ========================================================================== */
/*** banner styling ***/
.top-banner .banner {
    position: relative;
    background-color: #d3d3d3;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.top-banner .fallback { /* used on courses */
    background-image: url("../assets/img/fallback.jpg");
}
.top-banner .banner  .contentwrap {
    text-align: left;
    min-height: 450px;
    padding-top: 200px;
    padding-left: 0;
    padding-bottom: 0;
    box-sizing: border-box;
    z-index: 4;
}
.banner .pg-title {
    position: absolute;
    bottom: 0;
    display: inline-block;
    vertical-align: bottom;
    text-align: left;
    text-transform: uppercase;
    font-size: 50px;
    line-height: 1;
    padding: 20px;
    padding-bottom: 10px;
    width: auto;
    max-width: 50%;
    background-color: #FFF;
    box-sizing: border-box;
    margin: 0;
    margin-right: auto;
}
.logo-padding { /* no-banner page */
    padding-top: 100px;
}

.grey {
    background-color: #e5eaf0;
}
.grey .button {
    background-color: #a2a3a7 !important; /* grey */
    border: 0 !important;
}
.grey .button:hover {
    background-color: #242c41 !important;
    color: #FFF !important;
}

.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4,
.page-content p,
.page-content ul,
.page-content ol,
.course h1,
.course h2,
.course h3,
.course h4 {
    text-align: left;
}

/* course category colours */
.purple .highlight,
span.purple,
.purple .course-info h1,
.purple .course-info h2,
.purple .course-info h3,
.purple .course-info h4,
.purple .page-content h1,
.purple .page-content h2,
.purple .page-content h3,
.purple .page-content h4,
.purple .page-content span,
.purple .page-content .title,
.purple .page-content .highlight {
    color: #691F7D;
}
.purple .button,
.purple form button {
    background-color: #691F7D;
    border: 2px solid #691F7D;
}
.purple .button:hover,
.purple form button:hover {
    color: #691F7D;
    background-color: #FFF;
}

.pink .highlight,
span.pink,
.pink .course-info h1,
.pink .course-info h2,
.pink .course-info h3,
.pink .course-info h4,
.pink .page-content  h1,
.pink .page-content  h2,
.pink .page-content  h3,
.pink .page-content  h4,
.pink .page-content  span,
.pink .page-content  .title,
.pink .page-content  .highlight {
    color: #ed0d6e;
}
.pink .button,
.pink form button{
    background-color: #ed0d6e;
    border: 2px solid #ed0d6e;
}
.pink .button:hover,
.pink form button:hover {
    color: #ed0d6e;
    background-color: #FFF;
}

.mustard .highlight,
span.mustard,
.mustard .course-info h1,
.mustard .course-info h2,
.mustard .course-info h3,
.mustard .course-info h4,
.mustard .page-content  h1,
.mustard .page-content  h2,
.mustard .page-content  h3,
.mustard .page-content  h4,
.mustard .page-content  span,
.mustard .page-content  .title,
.mustard .page-content  .highlight {
    color: #f1b519;
}
.mustard .button,
.mustard form button {
    background-color: #f1b519;
    border: 2px solid #f1b519;
}
.mustard .button:hover,
.mustard form button:hover {
    color: #f1b519;
    background-color: #FFF;
}

.lime .highlight,
span.lime,
.lime .course-info h1,
.lime .course-info h2,
.lime .course-info h3,
.lime .course-info h4,
.lime .page-content  h1,
.lime .page-content  h2,
.lime .page-content  h3,
.lime .page-content  h4,
.lime .page-content  span,
.lime .page-content  .title,
.lime .page-content  .highlight {
    color: #bfd62e;
}
.lime .button,
.lime form button {
    background-color: #bfd62e;
    border: 2px solid #bfd62e;
}
.lime .button:hover,
.lime form button:hover {
    color: #bfd62e;
    background-color: #FFF;
}

.jade .highlight,
span.jade,
.jade .course-info h1,
.jade .course-info h2,
.jade .course-info h3,
.jade .course-info h4,
.jade .page-content  h1,
.jade .page-content  h2,
.jade .page-content  h3,
.jade .page-content  h4,
.jade .page-content  span,
.jade .page-content  .title,
.jade .page-content  .highlight {
    color: #005950;
}
.jade .button,
.jade form button{
    background-color: #005950;
    border: 2px solid #005950;
}
.jade .button:hover,
.jade form button:hover {
    color: #005950;
    background-color: #FFF;
}

.navy .highlight,
.blue,
.navy .course-info h1,
.navy .course-info h2,
.navy .course-info h3,
.navy .course-info h4,
.navy .page-content  h1,
.navy .page-content  h2,
.navy .page-content  h3,
.navy .page-content  h4,
.navy .page-content  span,
.navy .page-content  .title,
.navy .page-content  .highlight {
    color: #013974;
}
.navy .button,
.navy form button {
    background-color: #013974;
    border: 2px solid #013974;
}
.navy .button:hover,
.navy form button:hover {
    color: #013974;
    background-color: #FFF;
}

/* ==========================================================================
   Course General html
   ========================================================================== */
.course-details .mobile-header {
    display:none;
}
.course .contentwrap {
    z-index: 1;
}

/* ====== Course-Content editable content table ====== */
.grey.extra .contentwrap {
    padding: 0;
}
.extra-wrapper {
    margin-left: -40px;
    margin-right: -40px;
}
.grey.extra h2,
.grey.extra h3 {
    text-transform: uppercase;
}
.grey .button {
    margin-top: 40px;
}
.table.course-extra .col {
    padding: 40px;
    padding-bottom: 80px;
    transition: background-color 0.3s ease-in-out;
}
.table.course-extra .col:hover {
    background-color: #dadee9;
}

/* ====== Course-Content-Footer  include ====== */
#course-footer .contentwrap {
    padding: 0 20px;
}

#course-footer .col {
    text-align: center;
}

.col > a {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.col.usi {
    padding: 30px;
    border: 2px solid #69207e;
    border-radius: 10px;
    overflow: hidden;
}
.usi-link p {
    color: #2d2d2d;
    font-weight: bold;
    line-height: 1.2;
    margin-top: 0;
}
.usi-link .pink.button {
    position: relative;
    display: block;
    padding: 15px 20px;
    width: 80%;
    margin: 0 auto;
    max-width: 100%;
}
.usi-link .pink.button:after {
    content:"";
    position: absolute;
    right: -60px;
    bottom: -30px;
    display: block;
    width: 130px;
    height: 304px;
    background-image: url('../assets/img/student-id.png');
    background-size: contain;
    background-repeat: no-repeat;
}

#course-footer .learn {
    position: relative;
    background-size: cover;
    background-position: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFF;
    font-weight: 600;
    font-size: 18px;
    padding: 20px;
    box-sizing: border-box;
}
#course-footer .learn::before {
    content:"";
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.1);
    transition: background-color 0.3s ease-in-out;
}
#course-footer .learn:hover::before {
    background-color: rgba(0,0,0,0.5);
}
.col.learn > a:hover > span {
    background: #FFF !important;
    color: #2a303f !important;
     -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);
}

/** buttons **/
.pink.button {
    color: #FFF;
    background-color: #ed0d6e !important;
    border: 2px solid #ed0d6e;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.pink.button:hover {
    color: #ed0d6e !important;
    background-color: #FFF !important;
}
/** green on pink page **/
.pink #course-view.pink.button {
    background-color: #bfd62e !important;
    border: 2px solid #bfd62e;
}
.pink #course-view.pink.button:hover {
    color: #bfd62e !important;
    background-color: #FFF !important;
}


.learn .hollow.button {
    background: transparent;
    color: #FFF;
    border: 2px solid #FFF !important;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.24);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.24);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.24);
    transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
}
.learn .hollow.button:hover {
    background: #FFF !important;
    color: #2a303f !important;
     -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.0);
}

/* ==========================================================================
   Course-Category html
   ========================================================================== */
/*** category content area ***/
.course.individual > .contentwrap {
    padding-bottom: 80px;
}
.category-info {
    text-align: left;
    margin-bottom: 20px;
}
.course-title,
.purple .course-info h2.course-title,
.pink .course-info h2.course-title,
.mustard .course-info h2.course-title,
.lime .course-info h2.course-title,
.jade .course-info h2.course-title,
.navy .course-info h2.course-title {
    color: #282828;
    text-transform: uppercase;
    font-weight: normal;
}

/******           course list           ******/
.courses-index {
    background-color: #e5eaf0;
}
/*** view button expand/collapse ***/
.course-list-title {
    position: relative;
    text-transform: uppercase;
    color: #6a207f;
    padding-right: 220px;
}
#course-view {
    position: absolute;
    right: 0;
    color: #FFF;
    background-color: #ed0d6e;
    bottom: 10px;
}
/***** table *****/
table.course-list {
    border-spacing:0;
    width: 100%;
}
.tableFloatingHeaderOriginal {
    background-color: #e5eaf0;
}
.course-list tbody {
    background-color: #FFF;
    outline: 1px solid #bfbfc1;
}
.course-list th,
.course-list td {
    text-align: left;
    padding: 20px;
}
.course-list td.data-item {
    text-align: center;
}
.related-list table {
    background-color: #e5eaf0;
}
.related-list td {
    text-align: left;
    padding: 10px 20px;
}
.related-list table td {
    outline: 1px solid #bfbfc1;
}

.course-list th {
    text-align: right;
    text-transform: uppercase;
    font-size: 14px;
    color: #6e8fb2;
}

table.course-list td h3,
.related-list table td h3 {
    color: #000;
    font-weight: normal;
    font-size: 20px;
    margin: 0;
}
.course-title .highlight,
table td h3 .highlight {
    font-weight: bolder;
}

/** top row - details **/
.course-details .data-item .button {
    width: 100%;
}
.course-list .button {
    display: inline-block;
    padding: 10px 15px;
    font-size: 12px;
    background-color: #FFF;
    color: #282828 !important;
    border-radius: 10px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.course-list .button:hover {
    color: #FFF !important;
}

/** category classes **/
.purple .course-list .button {
    border: 1px solid #6a207f;
}
.purple .course-list .button:hover {
    background-color: #6a207f;
}

.pink .course-list .button {
    border: 1px solid #ed0d6e;
}
.pink .course-list .button:hover {
    background-color: #ed0d6e;
}

.mustard .course-list .button {
    border: 1px solid #f1b519;
}
.mustard .course-list .button:hover {
    background-color: #f1b519;
}

.lime .course-list .button {
    border: 1px solid #bfd62e;
}
.lime .course-list .button:hover {
    background-color: #bfd62e;
}

.jade .course-list .button {
    border: 1px solid #005950;
}
.jade .course-list .button:hover {
    background-color: #005950;
}

.navy .course-list .button,
.no-class .course-list .button {
    border: 1px solid #013974;
}
.navy .course-list .button:hover,
.no-class .course-list .button:hover {
    background-color: #013974;
}

.duration-more-info-link {
    cursor:pointer;
}

.duration-more-info-details {
    position:absolute;
    left:5%;
    right:5%;
    background:#fff;
    padding:20px;
    border-radius:20px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    display:none;
}
.duration-more-info-details.active {
    display:block;
}
.duration-more-info-details h4 {
    padding:0;
}
.avoid-break {
    white-space: nowrap;
}
/** 2nd row - description ***/
tr.course-description td {
    padding-top: 0;
}
.course-description p,
.course-description h2,
.course-description h3,
.course-description h4 {
    margin-top: 0;
}
.course-description p.last {
    margin: 0;
}
a.learn-more {
    margin-top: 20px;
}

/** last row - course divider **/
tr.divider td {
    padding: 0px 20px;
}
.divider hr {
    display: block;
    width: 100%;
    height: 1.5px;
    background-color: #c6c6c8;
    border: 0;
    margin: 0;
}
tr.divider:last-of-type {
    display: none;
}
/** pager **/
#pages {
    padding-top: 20px;
    text-align: center;
}
#pages li {
    background-color: #FFF;
    color: #ed0d6e; /* pink */
    border: 2px solid #ed0d6e;
    border-radius: 10px;
    margin: 5px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
li.disabled {
    display: none;
}
#pages li > a {
    padding: 10px 15px;
}
#pages li.active,
#pages li:hover {
    background-color: #ed0d6e;
    color: #FFF;
}
/** lime if pink category **/
.pink #pages li {/* lime */
    color: #bfd62e;
    border: 2px solid #bfd62e;
}
.pink #pages li.active,
.pink #pages li:hover {
    background-color: #bfd62e;
    color: #FFF;
}
/* ==========================================================================
   Individual Course html
   ========================================================================== */
.course .main {
    display:table;
    table-layout: fixed;
    width:100%;
}
.course-listing-content {
    display:table-cell;
    width: 70%;
    padding-right:5%;
    vertical-align:top;
    text-align: left;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.sidebar {
    position: relative;
    display: table-cell;
    vertical-align:top;
    width: 30%;
}
.overview {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: left;
    padding-left: 10%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index:1;
}
.overview h2,
.overview h4 {
    text-transform: uppercase;
}
.overview h4 {
    font-weight: bold;
    padding: 0;
    margin-bottom: 5px;
}
.overview p {
    margin: 10px 0;
}
.start ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}
.start li {
    margin-bottom: 10px;
}

/**** course enrolment form ****/
.enroll-now {
    display: inline-block;
    vertical-align: top;
    padding: 15px 10%;
    font-weight: bold;
    text-transform: uppercase;
    /* colors done by category button class */
    margin-top: 40px;
    cursor: pointer;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.enroll-now:hover  {
    color: #013974; /* blue default */
    background: #FFF;
}

.enrol-form {
    margin-top:30px;
    display:none;
}
.enrol-form label.error {
    position:static;
    margin:5px;
    color:red;
}

/**** course content ****/
/** list of courses in the same group **/
div.course-group {
    background-color: #f3f4f8;
    outline: 1px solid #bfbfc1;
}
div.course-group h4,
div.course-group p {
    padding: 10px 20px;
    margin:0;
}
div.course-group h4 {
    padding-bottom: 0;
}
.course-group nav > ul {
    display: block;
    background: #e5eaf0;
    outline: 1px solid #bfbfc1;
}
.course-group li.drop {
    position: relative;
    max-width: 100%;
    color: #000;
    text-transform: none;
    padding-left: 20px;
    padding-right: 40px;
    cursor: pointer;
}
.course-group li.drop::after {
    content:"";
    position: absolute;
    display: block;
    top:0;
    right:0;
    width: 40px;
    height: 40px;
    background-image: url('../assets/img/drop-list.svg');
    background-repeat: no-repeat;
    background-size: 30px 40px;
    webkit-transition: -webkit-transform 500ms ease;
    -moz-transition: -moz-transform 500ms ease;
    -o-transition: -o-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease, -ms-transform 500ms ease;;
}
.course-group li.drop.active::after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}
.course-group li.drop > span {
    display: inline-block;
    padding: 10px 0;
    padding-right: 20px;
    min-height: 40px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.course-group .course-list {
    display: none;
    padding:0;
    padding-bottom: 10px;
}
.course-group .course-list.active {
    display: block;
}
.course-group .course-list li > a {
    display: inline-block;
    font-weight: bold;
    padding: 10px 0;
}

.course-provider img {
    float:left;
}
.course-provider::after {
    content:"";
    clear:both;
    display:block;
}


.course .related-list table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px;
}

/** below course info **/

/* ==========================================================================
   Page html
   ========================================================================== */
.t-search .result {
    text-align: left;
}
.t-search .result h2 {
    margin-bottom: 0;
}

/***** venue-hire template (not responsive currently) *****/
.floorplan-wrap { /** around venue-hire floorplan **/
    background: #FFF;
    padding: 40px;
    text-align: center;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
#floorplan {
    background: url('../assets/img/venue/web-floorplan.gif');
    width: 100%;
    height: 0;
    position: relative;
    margin: 0 auto;
    z-index: 0;
    padding-top:61.011%;
    background-size:contain;
}
#floorplan .floorplan-icon {
    background: url('../assets/img/venue/eye-icon.png') center no-repeat;
    height: 3.577%;
    width: 2.1825%;
    display: block;
    position: absolute;
    background-size:contain;
}
span.wrap {
    position: relative;
    display: none;
    width: 300px;
    height: 300px;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
span.bg {
    position:absolute;
    display:none;
    bottom:0;
    left:0;
    right:0;
    height:20px;
    width:100%;
    padding:5px;
    background:#000;
    opacity:0.5;
    filter: alpha(opacity=50);
    z-index:101;
}
span.caption {
    position:absolute;
    display:none;
    bottom:0;
    left:0;
    right:0;
    height:20px;
    width:100%;
    padding:5px;
    color:#fff;
    z-index:102;
}

/* ==========================================================================
   Contact page
   ========================================================================== */
#form-result #content {
    padding: 10px 40px;
}
#address-block {
    display: table;
    width: 100%;
    height: 400px;
    padding: 40px 0;
}
.contact-address {
    display: table-cell;
    vertical-align: bottom;
    text-align: left;
    width: 50%;
    padding-right: 1%;
    padding-bottom: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/*** contact form! ***/
.contactForm form p {
    padding-top: 15px;
    margin: 0;
}
.contactForm button {
    margin-top: 15px;
}

/*** map column **/
.contact-map {
    position: relative;
    display: table-cell;
    width: 50%;
    padding-left: 1%;
    height: 300px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
#map-click {
    position: relative;
    display: none;
    width: 100%;
    height: 300px;
    z-index: 10;
}
.contact-map iframe {
    position: absolute;
    display: block;
    top:0;
    right:0;
    bottom:0;
    left:0;
    width: 100%;
    height: 100%;
}

/* ========== enquiry template ========== */
.contact-enquiry form {
    max-width: 600px;
    margin: 0 auto;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (min-width: 1600px){
/** home **/
    .banner-content {
        padding-right: 20px;
    }
    .banner-title,
    .banner-caption {
        width: 400px;
        max-width: 100%;
    }
}

@media only screen and (max-width: 1400px){
    .enrolling-info h3 {
        font-size:2vw;
    }
}
@media only screen and (max-width: 1350px){
/** home **/
    .scroll-wrapper {
        margin: 0;
    }
/** course individual **/
    .extra-wrapper {
        margin: 0;
    }
}
@media only screen and (max-width: 1200px){
/** header **/
    header .logo {
        padding: 20px;
    }
    header nav {
        font-size: 13px;
    }
    .bottom-bar nav li.depth-0 > a {
        width: 150px;
        height: 60px;
        line-height:1;
        padding: 5%;
        padding-top: 20px;
        box-sizing: border-box;
    }
    .bottom-bar nav li.depth-0:hover > ul {
        padding-left: 20px;
    }
    .bottom-bar nav li li:first-child .menu-graphic,
    .bottom-bar nav .menu-graphic {
        display: none;
    }
/** home **/
    .banner-content {
        padding-left: 40px;
    }
}
@media only screen and (max-width: 1000px){
/** header Mobile **/
    #mobile-menu {
        display:block;
    }
    .main-menu,
    .under-banner-nav .subtitle {
        display:none;
    }
    .under-banner-nav .title {
        margin-bottom: 0;
    }
    .slide-menu {
        display:block;
    }
    .slide-menu-active .site-content-menu-slide {
        right: 240px;
    }
    form.search {
        margin-right: 0;
    }
}
@media only screen and (max-width:960px){
/** header **/
    header .wrapper {
        padding-left: 140px;
    }
    header .logo {
        font-size: 14px;
        width: 140px;
        padding: 10px;
    }
    header .top-bar {
        padding-bottom: 10px;
    }
    header .top-bar > div,
    form.search {
        vertical-align: middle;
    }
    #mobile-menu {
        position: relative;
        bottom:0;
        right:0;
        display: inline-block;
        vertical-align: middle;
        margin: 2px;
        margin-left: 10px;
    }
    .bottom-bar {
        display: none;
    }
/** banners **/
    .top-banner .banner::after,
    .banner-stage::after {
        width: 20px;
    }
/** contentwraps **/
    .contentwrap,
    #content {
        padding: 20px;
    }
    .services {
        padding: 20px 0;
    }
    .courses-scroller {
        padding-top: 20px;
    }
    .home .page-content.has-content {
        padding-bottom: 20px;
    }
/** venue hire **/
    .floorplan-wrap {
        padding: 20px 10px;
    }
    .home-center .contentwrap {
        padding-top: 10px;
    }
    .home-center .table {
        display: block;
    }
/** home **/
    .courses-scroller,
    .course-search,
    .student-exp {
        display:block;
        width:auto;
        margin-bottom: 20px;
    }
    #course-search {
        padding-bottom: 0;
        height: auto;
    }
    #course-search h3 {
        margin-top: 0;
    }
    .student-exp, .links{
        padding-top:0;
    }
    .student-exp img {
        margin-top:0;
    }
    .links {
        display: table;
        width: 100%;
        height: 600px;
    }
    .links .img {
        padding-top: 100%;
    }
    .links .label .col {
        vertical-align: middle;
    }
    .courses-scroller, .student-exp {
        /*margin-right:0;*/
        border-right: 0;
    }
    .services nav ul,
    .be-inspired nav ul {
        display: block;
        width: 100%;
    }
    .services nav li,
    .be-inspired nav li {
        display: inline-block;
        vertical-align: top;
        width: 50%;
    }
/** course **/
    .course-footer,
    .uri-link,
    .enrolling-info {
        display:block;
        width:auto;
    }
    .uri-link {
        border-right:0;
        margin-bottom:30px;
    }
    .uri-link .graphic {
        bottom:0;
        height: 100%;
        padding-top: 0;
        right:55%;
    }
    .uri-link .text {
        position:static;
        padding-left:50%;
    }
    .enrolling-info h3 {
        font-size:24px;
    }
/** courses **/
    .top-banner .banner .contentwrap {
        padding-top: 40px;
        min-height: 300px;
    }
    .banner .pg-title {
        font-size: 25px;
        max-width: 100%;
    }
    #course-footer .contentwrap {
        padding: 0;
    }
    .table-wrapper {
        margin:0;
    }
    .table-wrapper .table {
        border-spacing: 20px;
    }
/** course individual **/
    .table.course-extra .col {
        padding: 20px;
    }
    .table.course-extra .col p,
    .table.course-extra .col ul,
    .table.course-extra .col ol {
        display: none;
    }
    .table.course-extra .col p:last-of-type {
        display: block;
    }
/* contact */
    #address-block,
    .contact-address,
    .contact-map {
        display: block;
        width: 100%;
    }
    #address-block {
         height: auto;
         padding: 20px 0;
    }
    .contact-map {
        margin-top: 20px;
    }
}
@media only screen and (max-width: 800px) {
/** home **/
    .banner-content {
        font-size: 30px;
        line-height: 1.2;
        padding-right: 40px;
    }
/*** course item ***/
    .course .main,
    .course-listing-content,
    .overview-wrapper {
        display:block;
        width:auto;
    }
    .course-listing-content {
        padding-right: 0;
        margin-bottom: 20px;
    }
    .overview {
        padding: 0;
        border-top: 1px solid #e5eaf0;
    }
    /* course list */
    tr.course-details > td {
        padding: 5px;
    }
/** course footer **/
    .usi-link p {
        display: none;
    }
    .learn .hollow.button {
        padding: 15px 1%;
        width: 100%;
        box-sizing: border-box;
    }
    .usi-link .pink.button:after {
        right: -50px;
        width: 100px;
        height: 200px;
    }
}
@media only screen and (max-width:760px) {
/** header **/
    header .top-bar {
        padding: 20px 0;
    }
    header .top-bar > div,
    header form.search {
        margin: 0 5px;
    }
    #mobile-menu {
        margin: 0;
        margin-left: 10px;
    }
    header .top-bar nav {
        display: none;
    }
    .slide-menu .extras {
        display: block;
    }
/** home **/
    .banners.banners-home {
        height:300px;
    }
    .banner-content {
        padding: 100px 40px;
        padding-bottom: 40px;
    }
    .banner-caption > span {
        padding: 15px 3%;
    }
    .services nav li,
    .be-inspired nav li {
        display: block;
        text-align: center;
        width: 100%;
    }
/*** course index ***/
    .course-list-title {
        padding-right: 0;
    }
    #course-view {
        position:static;
        display:block;
    }
    .course h2 {
        font-size:32px;
    }
    .course-list thead {
        display:none;
    }
    .course-list th,
    .course-list td {
        display: block;
        text-align: center;
        padding: 10px 20px;
    }
    .course-details td.separator,
    .course-details .data-item.empty {
        display:none;
    }
    .course-details .mobile-header {
        display: inline-block;
        padding: 5px 10px;
        font-weight: bold;
    }
    .course-details .data-item .button {
        width: auto;
        padding: 10px 15px;
    }
    table.course-list td h3 {
        text-align: center;
    }
    /* course footer */
    .col.usi {
        padding: 20px;
    }
/*** course item listing ***/
    h2.course-title {
        margin-top: 0;
    }
    .course.individual > .contentwrap {
        padding-bottom: 20px;
    }
    .course-footer {
        margin-top: 40px;
    }
    .enrolling-info > h2 {
        padding-bottom: 20px;
    }
    .enrolling-info .contact-us .table .col {
        display:block;
        width:auto;
    }
}
@media only screen and (max-width: 650px){
/** banners **/
    .top-banner .banner .contentwrap {
        min-height: 200px;
    }
/** home **/
    form .half,
    form .half:nth-of-type(odd),
    form .half:nth-of-type(even) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    #course-search form button {
        width: 100%;
        padding: 0 2%;
        box-sizing: border-box;
        float: none;
        margin-top: 15px;
    }
/** footer **/
    footer .logo img {
        display: inline-block;
        vertical-align: middle;
        width: 100px;
        margin-bottom:0;
    }
    footer .logo span {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
        width: 130px;
        font-size: 16px;
        text-align: left;
    }
    footer .logo {
        width: 100%;
        text-align: center;
        padding: 0;
        padding-bottom: 20px;
        float: none;
    }
    .footer-contact {
        text-align: center;
    }
    footer h2 {
        font-size: 25px;
    }
    footer .table,
    footer .bottom .table > div {
        display: block;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
    }
    .smartSkilled {
        text-align: center !important;
    }
/* contact */
    .contactForm p {
        display: block;
        width: 100%;
    }
/** course **/
    /** footer **/
    .course-footer {
        margin-top: 20px;
    }
    .extra-wrapper .table,
    .extra-wrapper .col,
    .table-wrapper .table,
    .table-wrapper .col{
        display: block;
        width: 100%;
        box-sizing: border-box;
    }
    .table-wrapper .table {
        padding: 20px;
    }
    .enrolling-info > h2 {
        display: none;
    }
    .col.usi img {
        width: 200px !important;
        max-width: 100%;
    }
    #course-footer .learn {
        display: none;
    }
    /** extras **/
    .table.course-extra .col {
        border-top: 1px solid #a2a3a7;
    }
    .table.course-extra > div.col:first-of-type {
        border-top: 0;
    }
}
@media only screen and (max-width:500px) {
/** header **/
    header .wrapper {
        padding-left: 0;
    }
    .logo img {
        display: inline-block;
        vertical-align: middle;
        width: 80px;
        margin-bottom:0;
    }
    .logo span {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
        width: 130px;
        font-size: 16px;
        text-align: left;
    }
    header .logo {
        position: static;
        text-align: center;
        display: block;
        width: 100%;
        padding: 0;
        padding-right: 40px;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing:border-box;
    }
    header form.search,
    header .top-bar div {
        display: none;
    }
    header .top-bar {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        padding:0;
    }
    #mobile-menu {
        position: absolute;
        top: 50%;
        right:0;
        margin-top: -22px;
        margin-left:0;
    }
/** home **/
    .home h2 {
        font-size: 25px;
        line-height: 1;
        margin-bottom: 10px;
    }
    .home-center:after {
        bottom:-0.5%;
    }
    .courses-scroller,
    #course-search p,
    .student-exp a > p:first-of-type{
        display: none;
    }
    #course-search h3 {
        text-align: center;
    }
    .be-inspired li .title,
    .be-inspired li .subtitle {
        text-align: center;
    }
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
/** page **/
    .logo-padding {
        padding:0;
    }
/*** course ***/
    .banner .pg-title {
        font-size: 16px;
        background-color: rgba(255,255,255, 0.8);
    }
    .course h2 {
        font-size:24px;
    }
    .enroll-now {
        margin-left:0;
    }
}